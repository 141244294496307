<template>
  <div class="panoramic-containei" v-if="!showModel">
    <div class="comback-box" @click="overallViewComback">返回</div>
    <iframe
      :src="panoramicUrl"
      scrolling="yes"
      frameborder="0"
      style="height: 100vh; width: 100%"
    >
    </iframe>
  </div>
  <div class="caseShare-container" v-if="showModel">
    <article class="case-details" v-if="loadingCase">
      <!-- 案例基础信息 -->
      <div class="case-base-info">
        <div class="case-info-card">
          <div class="mycase-box-info">
            <div class="case-box">
              <div class="cover-img">
                <div
                  class="house-tour"
                  v-if="oneCase.panoramicUrl"
                  @click="loadPanoramic"
                >
                  全景漫游
                </div>
                <img
                  :src="
                    oneCase.headImgUrl
                      ? `${imgUrl}/${oneCase.headImgUrl}`
                      : morenImg
                  "
                  alt=""
                />
                <!-- <img src="../../assets/icons/mimi.jpg" alt=""> -->
              </div>
              <div class="case-info">
                <div class="title">
                  <p>{{ oneCase.title }}</p>
                  <p>
                    {{ oneCase.typeName }} | {{ oneCase.area }}m² |
                    {{ oneCase.casePosition }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-sticky>
        <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
        <div v-else class="card-box-cls">
          <div class="card-box">
            <div class="card-info" @click="handleViewUserCard">
              <div class="card-left">
                <img
                  :src="
                    oneCase.userCard.headImgUrl
                      ? `${imgUrl}/${oneCase.userCard.headImgUrl}`
                      : morenHeadImg
                  "
                  alt=""
                />
                <!-- <img src="../../assets/icons/mimi.jpg" alt=""> -->
                <div class="creater-info">
                  <div class="title">{{ oneCase.userCard.name }}</div>
                  <div class="second">
                    {{ oneCase.userCard.impressionLabel }}
                  </div>
                </div>
              </div>
              <div class="card-right" @click.stop="handleCall">
                <!-- <svg class="phone">
                    <use xlink:href="/phone.svg#phone-contract"></use>
                    </svg> -->
                <div class="phone">电话联系</div>
              </div>
            </div>
            <div class="synopsis">
              <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
              <span
                style="color: #999"
                @click="handleIsFold"
                v-if="synopsisStr.length > 40"
                >{{ isFold ? "...展开" : "&lt;&lt;折叠" }}</span
              >
            </div>
          </div>
        </div>
      </van-sticky>
      <div class="case-design">
        <p>设计理念</p>
        <div class="decribe-text">
          {{ oneCase.idea }}
        </div>
        <div class="case-content">
          <div class="case-content-box">
            <transform-html :contents="oneCase.picDescription"></transform-html>
          </div>
        </div>
      </div>
    </article>
    <footer class="huoke-footer">
      <div class="article_list_buttom2">
        <van-tabbar v-model="active" active-color="#646566" @change="onChange">
          <van-tabbar-item>
            <icon-svg iconClass="iconsmartphone1" class="zixun" />
            <span>电话联系</span>
          </van-tabbar-item>
          <van-tabbar-item>
            <icon-svg iconClass="iconmessage-square" class="zixun" />
            <span>我要咨询</span>
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </footer>
    <!-- 咨询 -->
    <div class="consulting">
      <van-overlay :show="showConsulting" @click="showConsulting = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p class="title">我要咨询</p>
            <van-form ref="addInfoForm" error-message-align="right">
              <div class="info">
                <van-field
                  v-model="addInfo.username"
                  label="姓名"
                  :rules="[{ required: true, message: '请输入名称' }]"
                />
                <van-field
                  v-model="addInfo.phone"
                  label="手机号"
                  type="tel"
                  :rules="[
                    {
                      required: true,
                      pattern: /^1[3|4|5|7|8]\d{9}$/,
                      message: '无效手机号',
                    },
                  ]"
                />
                <van-field
                  v-model="addInfo.content"
                  label="留言"
                  type="textarea"
                  :rules="[{ required: true, message: '请输入留言' }]"
                />
              </div>
            </van-form>
            <van-grid clickable :column-num="2">
              <van-grid-item text="返回" @click="handlecomback" />
              <van-grid-item text="保存" @click="handleAddInfo" />
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, onUnmounted, ref } from "vue";
import { Dialog, Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import { findCaseById, createCaseConsult, addNum } from "../../api/case";
import {addClueBehaviorInfo} from "@/api/user/clue"
import { useWxAuthorization } from "@/hooks/wxAuthorization";
import transformHtml from "../../components/transformHTML.vue";
import $ from "jquery";
import { initWechatShare } from "../../hooks/initWechatShare";
import {addClue} from "../../api/user/clue";
export default {
  components: {
    transformHtml,
  },
  setup() {
    let internal = null;
    let seconds = 0;
    const route = useRoute();
    const router = useRouter();
    let addInfoForm = ref(null);
    const merchantId = route.params.merchantId;
    const recommendClueId = route.params.recommendClueId?route.params.recommendClueId:''
    const state = reactive({
      showSkeleton:true,
      showModel: true,
      panoramicUrl: "",
      morenImg: require("../../assets/images/case-defaultimg.png"),
      morenHeadImg: require("../../assets/icons/Vector.png"),
      active: null,
      showConsulting: false,
      loading: false,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      loadingCase: false,
      showShare: false,
      caseId: route.params.id,
      consultId: null,
      openId: null,
      oneCase: {},
      icon: {
        inactive: require("../../assets/icons/smartphone.png"),
        active: require("../../assets/icons/smartphone.png"),
        mycaseN: require("../../assets/icons/wechat-chat.png"),
        mycaseC: require("../../assets/icons/wechat-chat.png"),
        consult: require("../../assets/icons/message-square.png"),
      },
      addInfo: {
        username: "",
        phone: "",
        content: "",
        busiId: route.params.id,
      },
      synopsisStr: "",
      foldSynopsisStr: "",
      isFold: true,
      clueId: "",
    });
    //全景漫游
    const loadPanoramic = () => {
      state.showModel = !state.showModel;
      // let miniProgramPhone = route.query.state
      //   ? route.query.state
      //   : route.query.miniProgramPhone;
      // :href="oneCase.panoramicUrl"

      //  router.push({name:'panoramicWindow',params:{panoramicUrl:`${state.oneCase.panoramicUrl}`}})
      // router.push({
      //   name: "panoramicWindow",
      //   query: {
      //     panoramicUrl: state.oneCase.panoramicUrl,
      //     miniProgramPhone: miniProgramPhone,
      //     openId: state.openId,
      //   },
      // });
      // console.log('state.oneCase.panoramicUrl',state.oneCase.panoramicUrl);
      // router.push({path:'/panoramicWindow'})
    };

    const onChange = (index) => {
      // console.log("标志号",index);
      if (index === 0) {
        handleCall();
        state.active = null;
      }
      if (index === 1) {
        state.showConsulting = true;
      }
    };
    const handlecomback = () => {
      state.showConsulting = false;
      state.active = null;
      state.addInfo.content = "";
    };
    const handleAddInfo = () => {
      state.active = null;
      let param = {
        ...state.addInfo,
      };
      addInfoForm.value.validate().then(() => {
        createCaseConsult(param).then((data) => {
          if (data) {
            state.consultId = data.id;
            // Dialog.alert({ title: "成功", message: "您的咨询已创建" });
            Toast.success("您的咨询已创建");
            countUserAction({clueId:state.clueId,openId:state.openId,type:5})
            state.showConsulting = false;
          }
        });
      });
    };
    const loadCaseDetails = async () => {
      let result = await findCaseById(state.caseId);
      state.oneCase = result;
      state.loadingCase = true;
      state.synopsisStr = result.userCard.selfIntro?result.userCard.selfIntro:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！';
      state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
      state.panoramicUrl = result.panoramicUrl;
      state.showSkeleton = false
      console.log("案例详情", result);

    };
    //展开折叠
    //展开折叠
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    const useWxAuth = async ()=>{
     let data= await  useWxAuthorization()
      if (data) {
        state.openId = data.openId;
      } else {
        state.openId = route.query.openId;
      }

      if (state.openId) {
        await loadCaseDetails();
        await handleFirstAddClue()
        let temp = location.href.split("?")[0];
        if(recommendClueId){
          temp =temp.replace(recommendClueId,state.clueId)
        }else {
          temp = `${temp}/${state.clueId}`
        }
        countUserAction({clueId:state.clueId,openId:state.openId,type:1})
        let shareImg;
        if (state.oneCase.smallCoverPic) {
          shareImg = `${process.env.VUE_APP_WATCH_FILE_API}/${state.oneCase.smallCoverPic}`;
        } else {
          shareImg = "https://wca.ujiaku.com/upload/wx/share-default.png";
        }

        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv(function(res) {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.postMessage({
              data: {
                title: state.oneCase.title,
                img: shareImg,
                openId: state.openId,
                clueId: state.clueId,
                shareUrl:temp,
                type: 4
              },
            });
          } else {
            let title = state.oneCase.title;
            let desc = state.oneCase.title;
            let appPhone;
            if (localStorage.getItem("appPhone")) {
              appPhone = localStorage.getItem("appPhone")
            } else {
              appPhone = route.query.appPhone
            }
            let url = `${
                    process.env.VUE_APP_WECHAT_SHARE
            }/shareForward?shareUrl=${temp}?appPhone=${appPhone}`;
            let imgUrl = shareImg;
            initWechatShare({ title, desc, url, imgUrl,openId:state.openId,clueId:state.clueId,type:4 });
          }
        });
      }
    }
    // useWxAuthorization().then((data) => {
    //   if (data) {
    //     state.openId = data.openId;
    //   } else {
    //     state.openId = route.query.openId;
    //   }
    //   if (state.openId) {
    //     handleFirstAddClue().then(()=>{
    //       countUserAction({clueId:state.clueId,openId:state.openId,type:1})
    //     })
    //   }
    // });
    //电话
    const handleCall = () => {
      if (state.oneCase.userCard.contactNum) {
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${state.oneCase.userCard.contactNum}`);
        a.click();
        countUserAction({clueId:state.clueId,openId:state.openId,type:2})
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    const handleFirstAddClue = async () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      let param = {
        shareId: state.caseId,
        clueSource: 3,
        browseSecond: 0,
        beSharePhone: miniProgramPhone,
        openId: state.openId,
        title: state.oneCase.title,
        createId: state.oneCase.loginUserId,
        consultId: state.consultId,
        clueShareSource: route.params.clueShareSource,
        recommend_Clue_Id:recommendClueId,
        merchantId
      };
      let data = await addClue(param);
      state.clueId = data;
      return data;
    };
    const handleViewUserCard = () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      router.push({
        path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
      });
    };
    const handleAddClue = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
                ? route.query.state
                : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.caseId,
          clueSource: 3,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          title: state.oneCase.title,
          createId: state.oneCase.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        $.ajax({
          async: false,
          url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          type: "post",
          contentType: "application/json;charset=UTF-8",
          data: JSON.stringify(param),
        });
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleWindowHide = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
                ? route.query.state
                : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.caseId,
          clueSource: 3,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          title: state.oneCase.title,
          createId: state.oneCase.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        let blob = new Blob([JSON.stringify(param)], {
          type: "application/json",
        });
        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          blob
        );
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        handleAddClue();
        clearInterval(internal);
        seconds = 0;
      }
    };
    const countUserAction = async ({ clueId, openId, type }) => {
      /**
       * type:
       *  1查看
       *  2拨打
       *  3保存
       *  4转发
       *  5提交咨询
       *  6点赞
       *  7收藏
       *  8留言
       */

      if (clueId) {
        await addClueBehaviorInfo({ clueId, openId, type });
      }
    };
    onMounted(() => {
      addNum(route.params.id, "views");
      // loadHotArticle();
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      document.addEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.addEventListener("pagehide", handleWindowHide, false);
    });
    onUnmounted(() => {
      clearInterval(internal);
      handleAddClue();
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.removeEventListener("pagehide", handleWindowHide, false);
    });
    //全景图返回
    const overallViewComback = () => {
      state.showModel = !state.showModel;
    };
    useWxAuth()
    return {
      ...toRefs(state),
      overallViewComback,
      handleIsFold,
      addInfoForm,
      onChange,
      handlecomback,
      handleAddInfo,
      handleCall,
      handleViewUserCard,
      loadPanoramic,
    };
  },
};
</script>
<style lang="scss">
.panoramic-containei {
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  .comback-box {
    position: absolute;
    height: 36px;
    width: 60px;
    line-height: 36px;
    text-align: center;
    left: 0;
    top: 100px;
    color: white;
    font-size: 14px;
    background: rgba(46, 46, 46, 0.5);
    border-radius: 0 20px 20px 0;
    opacity: 0.5;
  }
}
.caseShare-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .case-details {
    flex: 1;
    overflow-x: hidden;
    color: rgba(0, 0, 0, 0.56);
    .card-box-cls {
      background-color: #fff;
      padding: 5px 16px 16px 16px;
    }
    .card-box {
      // margin: 5px 16px 16px 16px;
      padding: 12px;
      border-radius: 8px;
      text-align: left;
      background-color: #fff;
      box-shadow: 0px 0px 15px rgb(229, 229, 230);
      .synopsis {
        margin-top: 5px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.84);
      }
      .card-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .card-left {
          display: flex;
          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }
          .creater-info {
            margin-left: 8px;
            .title {
              font-weight: bold;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.84);
              margin-bottom: 3px;
            }
            .second {
              font-weight: normal;
              font-size: 11px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .card-right {
          .phone {
            width: 80px;
            height: 30px;
            background-color: #289fff;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
          }
        }
      }
    }
    .case-base-info {
      padding: 0 16px;
      border-bottom: 1px solid #f7f7f7;
      .case-namecard {
        //  border: 1px solid #cccccc;
        border-radius: 8px;
        box-shadow: 0 0 6px 6px #f7f7f7;
        .img-name {
          height: 40px;
          padding: 5px 16px;
          img {
            display: block;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: bold;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .create-describe {
          font-size: 12px;
          text-align: left;
          padding: 5px 16px;
        }
      }
      .mycase-box-info {
        flex: 1;
        padding: 5px 0;
        .case-box {
          font-size: 16px;
          text-align: left;
          .cover-img {
            height: 160px;
            position: relative;
            .house-tour {
              position: absolute;
              top: 50%;
              left: 50%;
              color: #fff;
              transform: translate(-50%, -50%);
              background: rgba(31, 30, 30, 0.5);
              opacity: 0.8;
              height: 20px;
              border-radius: 20px;
              font-size: 14px;
              padding: 8px 30px;
            }
            > img {
              display: block;
              border-radius: 4px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .case-info {
            //  height: auto;
            position: relative;
            display: flex;
            width: 343px;
            justify-content: space-between;
            .title {
              //  float: left;
              position: relative;
              width: 330px;
              > p {
                margin: 0;
                padding: 0;
              }
              > p:nth-child(1) {
                font-weight: 700;
                margin-top: 8px;
                color: #333333;
              }
              > p:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                margin: 4px 0;
                color: #666666;
              }
            }
            .share-del {
              top: 10px;
              position: relative;
              .share {
                height: 20px;
                width: 20px;
                background: url("../../assets/icons/share-3.png") no-repeat;
              }
              .del {
                height: 20px;
                margin-top: 15px;
                width: 20px;
                background: url("../../assets/icons/trash-2.png") no-repeat;
              }
            }
          }
        }
      }
    }
    .case-design {
      padding: 5px 16px;
      padding-bottom: 0;
      text-align: left;
      p {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
      }
      .decribe-text {
        color: #666;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .case-content {
        width: 100%;
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        background-color: #ffffff;
        .case-content-box {
          // height: 300px;
          // background-color: deeppink;
          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
          }
          border-bottom: 1px solid #f1f3f6;
        }
      }
      .case-design-details {
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        > span {
          color: #666;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .case-content {
      width: 100%;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .case-content-box {
        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 400;
        }
        border-bottom: 1px solid #f1f3f6;
      }
    }
  }
  .huoke-footer {
    position: relative;
    height: 50px;
    .add_article {
      height: 36px;
      width: 52px;
      color: #fff;
      line-height: 36px;
      border-radius: 8px;
      font-size: 28px;
      position: fixed;
      z-index: 2;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
      background-color: #ff6e01;
    }
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .zixun {
        display: block;
        margin-bottom: 3px;
        font-size: 22px;
      }
    }
  }
  //遮罩层
  .consulting {
    font-size: 14px;
    font-weight: bold;
    color: #0000;

    .van-field__label {
      width: 44px;
    }

    .van-field__control {
      font-weight: 500;
      width: 194px;
      border: 1px solid #bfbfbf;
      border-radius: 5px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .block {
      width: 303px;
      border-radius: 8px;
      background-color: #fff;
      color: black;

      > div {
        // height: 20px;
        > div:nth-child(1) {
          .van-hairline {
            border-radius: 0 0 0 10px;
            padding: 0;

            height: 46px;

            .van-grid-item__text {
              color: #999999;
            }
          }
        }

        > div:nth-child(2) {
          .van-hairline {
            border-radius: 0 0 10px 0;
            padding: 0;

            .van-grid-item__text {
              color: #333333;
            }
          }

          [class*="van-hairline"]::after {
            border-right: none;
          }
        }
      }

      [class*="van-hairline"]::after {
        border-bottom: none;
      }

      .name-card {
        background-color: #ffff;
        border-bottom: 1px solid #f1f3f6;
        padding: 0 16px;

        p {
          padding: 0;
          margin: 0;
        }

        .name-info {
          display: flex;
          text-align: left;
          font-size: 14px;
          color: #333333;
          padding: 8px 0;
          // justify-content: space-between;
          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }

          .creater-info {
            margin-left: 8px;

            > p:nth-child(1) {
              font-weight: 700;
            }

            > p:nth-child(2) {
              font-size: 12px;
              color: #666666;
            }
          }
        }

        .synopsis {
          text-align: left;
          font-size: 12px;
          color: black;
        }
      }

      .info {
        padding: 0 10px;

        .van-cell::after {
          border: none;
        }

        .van-field__label {
          color: black;
        }

        [class*="van-hairline"]::after {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
